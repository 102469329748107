<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="section-title">
        <h2>About Us</h2>
        <p>Operated company specializing in flooring solutions</p>
      </div>

      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2">
          <img src="../../assets/img/about2.jpg" class="img-fluid" alt="image" />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h3>Quality Flooring Services by a Local Contractor</h3>
          <p class="mt-3">Locally owned and operated business</p>
          <p>Proud of reputation as a quality flooring contractor</p>
          <p>Not just a sales-oriented company, but more than that</p>
          <p>Stand by quality work</p>
          <p>
            Offer all types of flooring installations and repairs (hardwood,
            carpet, tile, vinyl, laminate, linoleum)
          </p>
          <p>Experienced and knowledgeable staff</p>
          <p>Guarantee job done right the first time</p>
          <p>Contact for free consultation</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5cb874;
}

.about .content p:last-child {
  margin-bottom: 0;
}
</style>